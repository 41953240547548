<template>
  <b-card-code title="推广员信息填写">
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="微信名称" label-for="wechatname">
          <b-form-input id="readOnlyInput" :value="wechatname" readonly />
        </b-form-group>
      </b-col>
      <!-- phone number -->
      <b-col md="6" xl="4">
        <b-form-group label="电话号码" label-for="phone">
          <b-input-group>
            <cleave
              id="phone"
              v-model="phone"
              class="form-control"
              :raw="false"
              :options="options.phone"
              placeholder="136 8888 8888"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="渠道" label-for="channelid">
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="ChannelOptions"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="备注" label-for="remark">
          <b-form-textarea
            id="textarea-default"
            v-model="remark"
            placeholder="备注"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-button variant="relief-success" @click="submit"> 提交 </b-button>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.cn";
export default {
  components: {
    BCardCode,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    Cleave,
    BButton,
    BRow,
    BFormInput,
    BCol,
    BFormTextarea,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      phone: "",
      cid: "",
      wechatid: "",
      wechatname: "",
      remark: "",
      selected: "",
      ChannelOptions: [],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "CN",
        },
      },
    };
  },
  methods: {
    submit() {
      useJwt
        .updatebystatus({
          wechatid: this.wechatid,
          channelid: this.selected.value,
          phone: this.phone,
          remark: this.remark,
          cid: this.cid,
        })
        .then((res) => {
          console.log(JSON.stringify(res));
          if (res.data.code == "0") {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `信息录入成功`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `三秒后关闭窗口`,
              },
            });
            setTimeout(() => {
              WeixinJSBridge.call("closeWindow");
            }, 3000);
          }
        });
    },
  },
  created() {
    this.cid = this.$route.query.cid;
    this.wechatid = this.$route.query.wechatid;
    useJwt
      .queryFriendid({ cid: this.cid, friendid: this.wechatid })
      .then((res) => {
        console.log("res", JSON.stringify(res.data.nickname));
        if (res.data != null) {
          this.wechatname = res.data.nickname;
        }
      });
    //绑定渠道列表
    useJwt.queryChannelByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        console.log("response", JSON.stringify(response));
        const forArr = response.data.data;
        forArr.forEach((item, i) => {
          this.ChannelOptions.push({ title: item.channelname, value: item.id });
        });
      }
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>